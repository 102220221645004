import { Injector } from '@angular/core';
import { BaseWidget } from '@sds/widget-gridster';

export class ClosedTasksWidget extends BaseWidget<never> {
  static readonly id = 'closed-tasks';
  static readonly publicFriendly = false;

  static factory(injector: Injector, metaLinkId: string, x: number, y: number, rows: number, cols: number) {
    return new ClosedTasksWidget(injector, metaLinkId, x, y, rows, cols);
  }

  constructor(injector: Injector, metaLinkId: string, x: number, y: number, rows: number, cols: number) {
    super(injector, ClosedTasksWidget.id, ClosedTasksWidget.publicFriendly, metaLinkId, x, y, rows, cols);
    this.resolveComponent(async () => {
      const module = await import('../widgets/closed-tasks/closed-tasks.component');
      return Object.values(module)[0];
    });
  }
}
