import { Injector } from '@angular/core';
import { BaseWidget } from '@sds/widget-gridster';

export class OpenPlantCardsWidget extends BaseWidget<never> {
  static id = 'open-plant-cards';
  static readonly publicFriendly = false;

  static factory(injector: Injector, metaLinkId: string, x: number, y: number, rows: number, cols: number) {
    return new OpenPlantCardsWidget(injector, metaLinkId, x, y, rows, cols);
  }

  constructor(injector: Injector, metaLinkId: string, x: number, y: number, rows: number, cols: number) {
    super(injector, OpenPlantCardsWidget.id, OpenPlantCardsWidget.publicFriendly, metaLinkId, x, y, rows, cols);
    this.resolveComponent(async () => {
      const module = await import('../widgets/open-plant-cards/open-plant-cards.component');
      return Object.values(module)[0];
    });
  }
}
